import axios, { AxiosResponse } from 'axios'
import { MutationFunction, QueryFunction } from 'react-query'
import IOpportunity from '@interfaces/IOpportunity'

export const all: QueryFunction<AxiosResponse<IOpportunity[]>> = () => {
  return axios.get('opportunities/opportunity')
}

export const approve: MutationFunction<AxiosResponse<any, any>, [number, FormData]> = ([id, formData]) => {
  formData.append('_method', 'PUT')
  return axios.post(`opportunities/opportunity/${id}/approve`, formData)
}

export const reject: MutationFunction<AxiosResponse<any, any>, number> = (id) => {
  return axios.put(`opportunities/opportunity/${id}/reject`)
}

export const create: MutationFunction<AxiosResponse<any, any>, FormData> = (opportunity) => {
  return axios.post('opportunities/opportunity', opportunity)
}
export const remove = (opportunityId: any) => {
  return axios.delete(`opportunities/opportunity/${opportunityId}`)
}
