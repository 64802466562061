import axios, { AxiosResponse } from 'axios'
import { MutationFunction, QueryFunction } from 'react-query'
import INote from '@interfaces/INote'

export const all: QueryFunction<AxiosResponse<INote[]>> = () => {
  return axios.get('clients/all_clients')
}

export const find: QueryFunction<AxiosResponse<any, any>, [number]> = ({ queryKey: [ id ] }) => {
  return axios.get(`clients/all_clients/${id}`)
}

export const create: MutationFunction<AxiosResponse<any, any>, INote> = (note) => {
  return axios.post('clients/all_notes', note)
}

export const update: MutationFunction<AxiosResponse<any, any>, INote> = (note) => {
  return axios.put(`clients/all_notes/${note.id}`, note)
}

export const destroy: MutationFunction<AxiosResponse<any, any>, number> = (id) => {
  return axios.delete(`clients/all_notes/delete/${id}`)
}
