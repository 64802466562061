import React, { useMemo } from 'react'
import Mask from '@helpers/Mask'
import moment from 'moment'
import IOpportunity from '@interfaces/IOpportunity'
import { BsArrowRepeat, BsTrash } from 'react-icons/bs'

const status: any = {
  pending: 'Pendente',
  approved: 'Aprovado',
  rejected: 'Rejeitado',
  awaiting_renewal: 'Aguardando Renovação',
  renewal_rejected: 'Renovação Rejeitada',
}

type Props = {
  onApprove: (opportunity: IOpportunity) => void
  onReject: (opportunity: IOpportunity) => void
  onRemove: (opportunity: IOpportunity) => void
}

const useOpportunityColumns = ({ onApprove, onReject, onRemove }: Props) => useMemo(() => ([
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ row: { original: opportunity } }: any) => opportunity.id,
  },
  {
    Header: 'Cliente',
    accessor: 'client.company_name',
  },
  {
    Header: 'Razão Social',
    accessor: 'company_name',
  },
  {
    Header: 'Nome Fantasia',
    accessor: 'trading_name',
  },
  {
    Header: 'CNPJ',
    accessor: 'cnpj',
    Cell: ({ row: { original: opportunity } }: any) => Mask.cnpj(opportunity.cnpj),
  },
  {
    Header: 'Nº de Máquinas',
    accessor: 'computers_number',
    Cell: ({ row: { original: opportunity } }: any) => `${opportunity.computers_number} máquina` + (opportunity.computers_number === 1 ? '' : 's'),
  },
  {
    Header: 'Data de Vencimento',
    accessor: 'expires_at',
    Cell: ({ row: { original: opportunity } }: any) => opportunity.expires_at ? moment(opportunity.expires_at).format('DD/MM/YYYY') : (
      <span className="text-muted">N/A</span>
    ),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ row: { original: opportunity } }: any) => (
      <span className={`badge bg-${opportunity.status}`}>{status[opportunity.status]}</span>
    ),
  },
  {
    Header: 'Registrado em',
    accessor: 'created_at',
    Cell: ({ row: { original: opportunity } }: any) => moment(opportunity.created_at).format('DD/MM/YYYY [ás] HH:mm'),
  },
  {
    Header: 'Ações',
    disableSortBy: false,
    Cell: ({ row: { original: opportunity } }: any) => {
      if (['pending', 'awaiting_renewal'].includes(opportunity.status)) {
        return (
          <div className="btn-group">
            <button className="btn btn-primary btn-sm pe-2 ps-2" onClick={() => onApprove(opportunity)}>Aprovar</button>
            <button className="btn btn-outline-danger btn-sm pe-2 ps-2" onClick={() => onReject(opportunity)}>Rejeitar</button>
          </div>
        )
      }

      return (
        <div className="btn-group">
          <button
            className="btn btn-success btn-sm pe-2 ps-2"
            onClick={() => onApprove(opportunity)}
            title="Renovar oportunidade"
          >
            <BsArrowRepeat size={23} />
          </button>

          <button
            className="btn btn-primary btn-sm pe-2 ps-2 ms-2"
            onClick={() => onRemove(opportunity)}
            title="Excluir oportunidade"
          >
            <BsTrash size={23} />
          </button>
        </div>
      )

      return <span className="text-muted">N/A</span>
    },
    style: {
      width: 200,
      textAlign: 'right',
    },
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
]), [])

export default useOpportunityColumns