import React, { useContext, useState } from 'react'
import ClientContext from '@contexts/Client'
import { BsTrash } from 'react-icons/bs'
import IUser from '@interfaces/IUser'
import { AiOutlineUserAdd } from 'react-icons/ai'
import { Modal } from 'react-bootstrap'
import { random } from '@helpers/Utils'
import Mask from '@helpers/Mask'
import { useMutation } from 'react-query'
import { create, update, destroy as $destroy } from '@services/User'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Phone from '@components/Form/Phone/Phone'

const Users: React.FC<any> = () => {
  const { client, refetch } = useContext(ClientContext)
  const [user, setUser] = useState<IUser | null>(null)

  const SweetAlert = withReactContent(Swal)

  const { mutate, isLoading } = useMutation((data: IUser) => user?.id && user.id > 0 ? update(data) : create(data))
  const { mutate: destroy } = useMutation($destroy)

  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => setUser({
    ...user || {},
    [name]: value,
  } as IUser)

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target
    setUser({
      ...user || {},
      [name]: value,
    } as IUser)
  }
  const handleIsOwner = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => setUser({
    ...user,
    is_owner: checked,
  } as IUser)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!user)
      return

    SweetAlert.fire({
      title: 'Aguarde...',
      text: user.id > 0 ? 'Atualizando usuário...' : 'Registrando usuário...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    mutate(user, {
      onSuccess: () => {
        refetch()
        SweetAlert.fire({
          title: 'Sucesso!',
          text: user.id > 0 ? 'Usuário atualizado com sucesso!' : 'Usuário registrado com sucesso!',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        }).then(() => setUser(null))
      },
      onError: (error: any) => {
        SweetAlert.fire({
          title: 'Erro!',
          text: error?.response?.data?.message || 'Ocorreu um erro ao ' + (user.id > 0 ? 'atualizar' : 'registrar') + ' o usuário!',
          icon: 'error',
          showConfirmButton: true,
        })
      },
    })
  }

  const onDelete = (user: IUser) => {
    SweetAlert.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá reverter essa ação!',
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        SweetAlert.fire({
          title: 'Aguarde...',
          text: 'Deletando usuário...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => SweetAlert.showLoading(),
        })

        destroy(user.id, {
          onSuccess: () => {
            refetch()
            SweetAlert.fire({
              title: 'Sucesso!',
              text: 'Usuário deletado com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          },
          onError: (error: any) => {
            SweetAlert.fire({
              title: 'Erro!',
              text: error?.response?.data?.message || 'Ocorreu um erro ao deletar o usuário!',
              icon: 'error',
              showConfirmButton: true,
            })
          },
        })
      }
    })
  }

  return (
    <>
      <Modal
        show={!!user}
        onHide={() => setUser(null)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{user?.id && user.id > 0 ? 'Atualizar usuário' : 'Adicionar usuário'}</Modal.Title>
        </Modal.Header>

        <form onSubmit={onSubmit}>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className="form-group mb-3">
                  <label htmlFor="name">Nome:</label>
                  <input type="text" name="name" id="name" className="form-control" defaultValue={user?.name} onChange={handleInputChange} />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="email">E-mail:</label>
                  <input type="text" name="email" id="email" className="form-control" defaultValue={user?.email} onChange={handleInputChange} />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="phone">Telefone:</label>
                  <Phone type="text" name="phone" id="phone" className="form-control" defaultValue={user?.phone ? Mask.phone(user.phone) : ''} onChange={handleInputChange} />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="password">Senha:</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    autoComplete="new-password"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="password_confirmation">Confirmação de senha:</label>
                  <input
                    type="password"
                    name="password_confirmation"
                    id="password_confirmation"
                    className="form-control"
                    autoComplete="new-password"
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-check">
                  <input
                    id="owner"
                    type="checkbox"
                    className="form-check-input"
                    defaultChecked={!client?.user_id || user?.id === client.user_id}
                    onChange={handleIsOwner}
                    disabled={!client?.user_id}
                  />
                  <label className="form-check-label" htmlFor="owner">Usuário principal</label>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div>
                  <label htmlFor="tipoCliente">Representante Comercial?</label>
                  <select
                    className="form-control"
                    name="tipoCliente"
                    defaultValue={user?.tipoCliente || ''}
                    onChange={handleSelectChange}
                  >
                    <option value="" disabled>Selecione</option>
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                </div>
              </div>

            </div>
          </Modal.Body>

          <Modal.Footer>
            <button type="submit" className="btn btn-primary pe-3 ps-3" disabled={isLoading}>
              {user?.id && user.id > 0 ? 'Atualizar' : 'Salvar'}
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-end mb-2">
            <button
              className="btn btn-link btn-sm d-flex align-items-center p-0"
              onClick={() => setUser({
                id: -random(),
                client_id: client?.id,
                is_owner: !client?.user_id,
              } as IUser)}
            >
              Adicionar usuário <AiOutlineUserAdd className="ms-1" size={20} />
            </button>
          </div>

          <div className="table-responsive">
            <table className="table table-secondary table-striped table-md mb-0">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Telefone</th>
                  <th>Representante Comercial</th>
                  <th>Proprietário</th>
                  <th style={{ width: 150 }} className="text-right">Ações</th>
                </tr>
              </thead>

              <tbody>
                {client?.users ? client.users.map((user: IUser) => (
                  <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{Mask.phone(user.phone || '', true)}</td>
                    <td>
                      {user.tipoCliente === 1 ? (
                        <span className="badge bg-success">Sim</span>
                      ) : (
                        <span className="text-muted">Não</span>
                      )}
                    </td>
                    <td>
                      {client.user_id === user.id ? (
                        <span className="badge bg-success">Sim</span>
                      ) : (
                        <span className="text-muted">Não</span>
                      )}
                    </td>
                    <td className="text-right">
                      <button className="btn btn-sm btn-outline-danger me-3" onClick={() => onDelete(user)}>
                        <BsTrash />
                      </button>

                      <button
                        className="btn btn-sm btn-primary ml-2"
                        onClick={() => setUser(user)}
                      >
                        Editar
                      </button>
                    </td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan={5} className="text-center">Nenhum usuário registrado.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default Users