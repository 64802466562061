import React, { useState, useMemo } from 'react'
import Table from '@components/Table/Table'
import Pagination from '@components/Pagination/Pagination'
import { usePagination, useSortBy, useTable } from 'react-table'
import useClientColumns from '@hooks/useClientColumns'
import { List } from './Clients.styles'
import { Link } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import * as $Client from '@services/Client'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const Clients: React.FC<any> = () => {
  // Carregar os dados dos clientes
  const { isLoading, data: response } = useQuery('clients', $Client.all)
  const SweetAlert = withReactContent(Swal)
  const approve = useMutation($Client.approve)
  const reject = useMutation($Client.reject)
  const destroy = useMutation($Client.destroy)

  // Estado para gerenciar o termo de pesquisa
  const [searchTerm, setSearchTerm] = useState('')

  const columns = useClientColumns({
    onApprove: (id: number) => {
      SweetAlert.fire({
        title: 'Aprovar cliente',
        text: 'Tem certeza que deseja aprovar este cliente?',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aprovar',
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          SweetAlert.fire({
            title: 'Aguarde...',
            text: 'Aguarde enquanto o cliente é aprovado...',
            icon: 'info',
            allowOutsideClick: false,
            showCancelButton: false,
            allowEscapeKey: false,
            didOpen: () => SweetAlert.showLoading(),
          })

          approve.mutate(id, {
            onSuccess: () => {
              SweetAlert.close()
            },
            onError: () => SweetAlert.close(),
          })
        }
      })
    },
    onReject: (id: number) => {
      SweetAlert.fire({
        title: 'Rejeitar cliente',
        text: 'Tem certeza que deseja rejeitar este cliente?',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Rejeitar',
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          SweetAlert.fire({
            title: 'Aguarde...',
            text: 'Aguarde enquanto o cliente é rejeitado...',
            icon: 'info',
            allowOutsideClick: false,
            showCancelButton: false,
            allowEscapeKey: false,
            didOpen: () => SweetAlert.showLoading(),
          })

          reject.mutate(id, {
            onSuccess: () => {
              SweetAlert.close()
            },
            onError: () => SweetAlert.close(),
          })
        }
      })
    },
    onDelete: (id: number) => {
      SweetAlert.fire({
        title: 'Excluir cliente',
        text: 'Tem certeza que deseja excluir este cliente?',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Excluir',
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          SweetAlert.fire({
            title: 'Aguarde...',
            text: 'Aguarde enquanto o cliente é excluído...',
            icon: 'info',
            allowOutsideClick: false,
            showCancelButton: false,
            allowEscapeKey: false,
            didOpen: () => SweetAlert.showLoading(),
          })

          destroy.mutate(id, {
            onSuccess: () => {
              SweetAlert.close()
            },
            onError: () => SweetAlert.close(),
          })
        }
      })
    },
  })

  // Memoize os dados filtrados para evitar recalcular em toda renderização
  const filteredClients = useMemo(() => {
    if (!response?.data) return []

    return response.data.filter(client => {
      const companyName = client.company_name || ''
      const tradingName = client.trading_name || ''
      const email = client.email || ''

      return (
        companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tradingName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        email.toLowerCase().includes(searchTerm.toLowerCase())
      )
    })
  }, [response, searchTerm])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: filteredClients, // Usando os dados filtrados
  } as any, useSortBy, usePagination)

  return (
    <>
      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <div>
          <h1 className="page-title mb-0">Clientes</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Início</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Clientes</li>
            </ol>
          </nav>
        </div>

        {/* Campo de busca com 'me-auto' para empurrar à esquerda */}
        <div className="mb-3 me-auto ms-3">
          <form onSubmit={(e) => e.preventDefault()}>
            <input
              type="text"
              className="form-control"
              placeholder="Buscar cliente..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </form>
        </div>
        <Link to="/clientes/registrar" className="btn btn-outline-primary pe-3 ps-3">Registrar cliente</Link>
      </div>

      <div className="card mb-3">
        <div className="card-body p-0">
          <List className="table-responsive">
            <Table
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              page={page}
              headerGroups={headerGroups}
              prepareRow={prepareRow}
              isLoading={isLoading}
              data={filteredClients}
            />
          </List>
        </div>

        <div className="card-footer d-flex justify-content-end">
          <Pagination
            page={pageIndex}
            pageCount={pageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            onClick={gotoPage}
          />
        </div>
      </div>
    </>
  )
}

export default Clients
