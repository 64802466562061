import React, { useContext, useEffect, useMemo } from 'react'
import ClientContext from '@contexts/Client'
import Mask from '@helpers/Mask'
import Cnpj from '@components/Form/Cnpj/Cnpj'
import IClient from '@interfaces/IClient'
import { useMutation } from 'react-query'
import { create, update } from '@services/Client'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { Logotipo } from './Main.styles'
import Phone from '@components/Form/Phone/Phone'
import objectToFormData from '@helpers/ObjectToFormData'

const Main: React.FC<any> = () => {
  const { id, client, setClient } = useContext(ClientContext)
  const [logotipo, setLogotipo] = React.useState<string | null>(null)

  const navigate = useNavigate()
  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    if (client && logotipo === null && client.logotipo?.full_url) {
      // console.log('testando o restorno de dados', client)
      setLogotipo(client.logotipo.full_url)
    }
  }, [client, logotipo])

  const { mutate, isLoading } = useMutation((data: IClient) => {
    const formData = objectToFormData(data, {
      exclude: [
        'users', 'documents', 'notes',
      ],
    })

    if (data.id > 0) {
      return update([data.id, formData])
    } else {
      return create(formData)
    }
  })

  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => setClient({
    ...client,
    [name]: value,
  } as IClient)

  const handleFileChange = ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
    if (files && files[0]) {
      setLogotipo(URL.createObjectURL(files[0]))
      setClient({
        ...client,
        logotipo: files[0],
      } as IClient)
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!client)
      return

    SweetAlert.fire({
      title: id ? 'Atualizando cliente' : 'Registrando cliente',
      text: 'Aguarde enquanto o cliente é ' + (id ? 'atualizado' : 'registrado') + '...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    mutate(client, {
      onSuccess: ({ data }) => SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Cliente ' + (id ? 'atualizado' : 'registrado') + ' com sucesso!',
        icon: 'success',
        confirmButtonText: 'Ok',
      }).then(() => {
        if (data.id) {
          navigate(`/clientes/${data.id}`)
        } else {
          // Trate o caso onde o id não está presente
          console.error('ID do cliente não retornado pela operação de atualização.')
        }
      }),
      onError: (e: any) => {
        if (e.response?.data?.errors) {
          const errors = e.response.data.errors

          const html = Object.values(errors).map((value: any) => {
            return value.map((message: string) => `<li>${message}</li>`).join('')
          }).join('')

          SweetAlert.fire({
            title: 'Erro!',
            icon: 'error',
            confirmButtonText: 'Ok',
            html,
          })
        } else {
          SweetAlert.fire({
            title: 'Erro!',
            text: e.response?.data?.message ?? 'Ocorreu um erro ao ' + (id ? 'atualizar' : 'registrar') + ' o cliente!',
            icon: 'error',
          })
        }
      },
    })
  }

  return (
    <div className="card">
      <form onSubmit={onSubmit}>
        <div className="card-body pb-0">
          <div className="row align-content-stretch">
            <div className="col-2 mb-3">
              <Logotipo
                className={logotipo && logotipo.length > 0 ? 'has-image' : ''}
                style={{
                  backgroundImage: `url(${logotipo})`,
                }}
              >
                <input type="file" name="logo" onChange={handleFileChange} />
                <span className="info">
                  {logotipo && logotipo.length > 0 ? 'Alterar logotipo' : 'Enviar logotipo'}
                </span>
              </Logotipo>
            </div>

            <div className="col-10">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="trading_name">Nome Fantasia:</label>
                    <input type="text" name="trading_name" id="trading_name" className="form-control" defaultValue={client?.trading_name} onChange={handleInputChange} />
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="company_name">Razão Social:</label>
                    <input type="text" name="company_name" id="company_name" className="form-control" defaultValue={client?.company_name} onChange={handleInputChange} />
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group mb-3">
                    <label htmlFor="cnpj">CNPJ:</label>
                    <Cnpj type="text" name="cnpj" id="cnpj" className="form-control" defaultValue={Mask.cnpj(client?.cnpj || '')} onChange={handleInputChange} />
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group mb-3">
                    <label htmlFor="email">E-mail:</label>
                    <input type="email" name="email" id="email" className="form-control" defaultValue={client?.email} onChange={handleInputChange} />
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group mb-3">
                    <label htmlFor="phone">Telefone:</label>
                    <Phone type="text" name="phone" id="phone" className="form-control" defaultValue={Mask.phone(client?.phone || '', true)} onChange={handleInputChange} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer d-flex justify-content-end">
          <button type="submit" className="btn btn-primary pe-3 ps-3 btn-sm" disabled={!client?.trading_name || isLoading}>
            {client?.id ? 'Atualizar' : 'Salvar'}
          </button>
        </div>
      </form>
    </div>
  )
}

export default Main
