import axios, { AxiosResponse } from 'axios'
import { MutationFunction, QueryFunction } from 'react-query'
import IUser from '@interfaces/IUser'

export const all: QueryFunction<AxiosResponse<IUser[]>> = () => {
  return axios.get('administrator/all_admin')
}

export const find: QueryFunction<AxiosResponse<any, any>, [number]> = ({ queryKey: [ id ] }) => {
  return axios.get(`administrator/all_admin/${id}`)
}

export const create: MutationFunction<AxiosResponse<any, any>, IUser> = admin => {
  return axios.post('administrator/register', admin)
}

export const update: MutationFunction<AxiosResponse<any, any>, IUser> = admin => {
  return axios.put(`administrator/update/${admin.id}`, admin)
}

export const destroy: MutationFunction<AxiosResponse<any, any>, number> = (id) => {
  return axios.delete(`administrator/delete/${id}`)
}
