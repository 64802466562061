import axios from 'axios'

export const securityCode = (email: string, password: string) => axios.post('clients/security_code', {
  email,
  password,
})

export const login = (email: string, password: string, securityCode: string): Promise<any> => axios.post('clients/login', {
  email,
  password,
  security_code: securityCode,
})

export const me = (): Promise<any> => axios.get('clients/me')

export const forgotPassword = (email: string): Promise<Response> => axios.post('clients/forgot_password', {
  email,
})

export const resetPassword = (token: string, email: string, password: string, passwordConfirmation: string): Promise<Response> => axios.post('clients/reset_password', {
  token,
  email,
  password,
  password_confirmation: passwordConfirmation,
})
