import axios, { AxiosResponse } from 'axios'
import { MutationFunction, QueryFunction } from 'react-query'
import IClient from '@interfaces/IClient'

export const all: QueryFunction<AxiosResponse<IClient[]>> = () => {
  return axios.get('clients/all_clients')
}

export const find: QueryFunction<AxiosResponse<any, any>, [number]> = ({ queryKey: [ id ] }) => {
  return axios.get(`clients/all_clients/${id}`)
}

export const create: MutationFunction<AxiosResponse<any, any>, FormData> = (formData) => {
  return axios.post('clients/all_clients', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const update: MutationFunction<AxiosResponse<any, any>, [number, FormData]> = ([id, formData]) => {
  formData.append('_method', 'PUT')
  return axios.put(`clients/all_clients/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const approve: MutationFunction<AxiosResponse<any, any>, number> = (id) => {
  return axios.post(`clients/all_clients/${id}/approve`)
}

export const reject: MutationFunction<AxiosResponse<any, any>, number> = (id) => {
  return axios.post(`clients/all_clients/${id}/reject`)
}

export const destroy: MutationFunction<AxiosResponse<any, any>, number> = (id) => {
  return axios.delete(`clients/all_clients/${id}`)
}
