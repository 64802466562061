import React, { useState, useMemo } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { usePagination, useSortBy, useTable } from 'react-table'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as $Opportunity from '@services/Opportunity'
import Table from '@components/Table/Table'
import Pagination from '@components/Pagination/Pagination'
import useOpportunityColumns from '@hooks/useOpportunityColumns'
import IOpportunity from '@interfaces/IOpportunity'
import Action from './Action/Action'
import Opportunity from './Opportunity/Opportunity'

const Opportunities: React.FC<any> = () => {
  const [opportunity, setOpportunity] = useState<IOpportunity | null>(null)
  const [showRegister, setShowRegister] = useState(false)

  // Estado para o termo de busca
  const [searchTerm, setSearchTerm] = useState('')

  const SweetAlert = withReactContent(Swal)

  const { isLoading, data: response } = useQuery('opportunities', $Opportunity.all)

  const reject = useMutation($Opportunity.reject)
  const remove = useMutation($Opportunity.remove)

  const onReject = (opportunity: IOpportunity) => {
    SweetAlert.fire({
      title: 'Rejeitar Oportunidade',
      text: 'Tem certeza que deseja rejeitar esta oportunidade?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        SweetAlert.fire({
          title: 'Aguarde...',
          text: 'Rejeitando oportunidade...',
          icon: 'info',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          didOpen: () => SweetAlert.showLoading(),
        })

        reject.mutate(opportunity.id, {
          onSuccess: () => {
            SweetAlert.fire({
              title: 'Sucesso!',
              text: 'Oportunidade rejeitada com sucesso!',
              icon: 'success',
            })
          },
          onError: () => {
            SweetAlert.fire({
              title: 'Erro!',
              text: 'Ocorreu um erro ao rejeitar a oportunidade!',
              icon: 'error',
            })
          }
        })
      }
    })
  }

  const onRemove = (opportunity: IOpportunity) => {
    SweetAlert.fire({
      title: 'Excluir Oportunidade',
      text: `Tem certeza que deseja excluir a oportunidade de "${opportunity.company_name}"?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        SweetAlert.fire({
          title: 'Aguarde...',
          text: 'Excluindo oportunidade...',
          icon: 'info',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          didOpen: () => SweetAlert.showLoading(),
        })

        remove.mutate(opportunity.id, {
          onSuccess: () => {
            SweetAlert.fire({
              title: 'Sucesso!',
              text: 'Oportunidade Excluída com sucesso!',
              icon: 'success',
            })
          },
          onError: () => {
            SweetAlert.fire({
              title: 'Erro!',
              text: 'Ocorreu um erro ao excluir a oportunidade!',
              icon: 'error',
            })
          }
        })
      }
    })
  }

  const columns = useOpportunityColumns({
    onApprove: (opportunity: IOpportunity) => setOpportunity(opportunity),
    onReject,
    onRemove
  })

  // Filtrando os dados com base no termo de pesquisa
  const filteredOpportunities = useMemo(() => {
    if (!response?.data) return []

    return response.data.filter(opportunity => {
      const companyName = opportunity.company_name || ''
      const clientCompanyName = opportunity.client?.company_name || ''
      const clientTradingName = opportunity.client?.trading_name || ''
      const tradingName = opportunity.trading_name || ''

      // Verifica se o termo de pesquisa está presente no nome da empresa ou do cliente
      return (
        companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        clientCompanyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        clientTradingName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tradingName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    })
  }, [response, searchTerm])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: filteredOpportunities, // Usando os dados filtrados
  } as any, useSortBy, usePagination)

  const onClose = () => {
    setOpportunity(null)
    setShowRegister(false)
  }

  return (
    <>
      <Action opportunity={opportunity} onClose={onClose} />

      <Opportunity show={showRegister} onClose={onClose} />

      <div className="page-header d-flex justify-content-between align-items-center mb-3">
        <div>
          <h1 className="page-title mb-0">Gestão de Oportunidades</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Início</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Gestão de Oportunidades</li>
            </ol>
          </nav>
        </div>
        <div className="mb-3 ms-3 me-auto">
          <input
            type="text"
            className="form-control"
            placeholder="Buscar oportunidade por nome da empresa ou cliente..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <button className="btn btn-outline-primary" onClick={() => setShowRegister(true)}>Registrar Oportunidade</button>
      </div>

      <div className="card mb-3">
        <div className="card-body p-0">
          <div className="table-responsive">
            <Table
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              page={page}
              headerGroups={headerGroups}
              prepareRow={prepareRow}
              isLoading={isLoading}
              emptyMessage="Não há oportunidades registradas."
            />
          </div>
        </div>

        <div className="card-footer d-flex justify-content-end">
          <Pagination
            page={pageIndex}
            pageCount={pageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            onClick={gotoPage}
          />
        </div>
      </div>
    </>
  )
}

export default Opportunities
